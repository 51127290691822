import React from 'react';
import { NextSeo } from 'next-seo';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import LiveStreamLandingPage from '~/pages/LiveStreamLandingPage';
import PipedriveChat from '~/components/PipedriveChat';

import DailyNewCurrentPropertyCount from '~/queries/DailyNewCurrentPropertyCount.graphql';
import AgentBriefs from '~/queries/AgentBriefs.graphql';
import AgentBriefPropertyAlertsCount from '~/queries/AgentBriefPropertyAlertsCountQuery.graphql';

type Props = { totalAgentBriefs: number; totalCurrentPropertyDaily: number; totalAlertsCount: number };

const IndexPage = (props: Props) => {
    const { totalAgentBriefs, totalCurrentPropertyDaily, totalAlertsCount } = props;

    const title = `Digital Marketplace for Agent | Realty.com.au`;
    const metaDescription =
        'Access pre-market, off market and passive properties in our private buyers agent marketplace. Instant AI Property Alerts.';

    return (
        <>
            <NextSeo
                title={title}
                description={metaDescription}
                canonical="https://www.realty.com.au"
                openGraph={{
                    type: 'product',
                    title,
                    description: metaDescription,
                    locale: 'en_US',
                    url: `https://www.realty.com.au`,
                    images: [
                        {
                            url: 'https://au.cdn.realty.com.au/assets/realtycomau_home_new.png',
                            width: 1200,
                            height: 628,
                            alt: 'Digital Marketplace for Agent | Realty.com.au',
                        },
                    ],
                }}
            />

            <PipedriveChat companyId={4209319} playbookUuid="ee9d5577-fa3c-413c-890b-a0dca765aa99" />

            <LiveStreamLandingPage
                totalCurrentPropertyDaily={totalCurrentPropertyDaily}
                totalAgentBriefs={totalAgentBriefs}
                totalAlertsCount={totalAlertsCount}
            />
        </>
    );
};

export const getServerSideProps = async () => {
    const apolloClient = new ApolloClient({
        uri: process.env.NEXT_PUBLIC_CLIENT_API,
        credentials: 'same-origin',
        assumeImmutableResults: true,
        name: process.env.NEXT_PUBLIC_APP_CLIENT_NAME,
        version: process.env.NEXT_PUBLIC_APP_CLIENT_VERSION,
        cache: new InMemoryCache({ addTypename: true }),
    });

    // Fetch daily property count
    const { data } = await apolloClient.query({
        query: DailyNewCurrentPropertyCount,
    });
    const { adminPropertyStatistics } = data || {};
    const { dailyNewCurrentPropertyCount } = adminPropertyStatistics || {};
    const totalCurrentPropertyDaily = dailyNewCurrentPropertyCount?.at(-1)?.total ?? 0;

    // Fetch total agent briefs
    const { data: briefsData } = await apolloClient.query({
        query: AgentBriefs,
        variables: {
            options: {
                isActive: true,
                isIncludeTestAgency: false,
            },
        },
    });
    const totalAgentBriefs = briefsData?.agentBriefsConnection?.totalCount ?? 0;

    // Fetch total alerts count
    const { data: alertsData } = await apolloClient.query({
        query: AgentBriefPropertyAlertsCount,
        variables: {
            input: {
                isActiveBrief: true,
                isIncludeTestAgency: false,
            },
        },
    });
    const totalAlertsCount = alertsData?.adminPropertyStatistics?.agentBriefPropertyAlertsCount ?? 0;

    return {
        props: {
            totalAgentBriefs,
            totalCurrentPropertyDaily,
            totalAlertsCount,
        },
    };
};

export default IndexPage;
